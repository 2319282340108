#admin-logged {
    position: fixed;
    left: 5px;
    bottom: 5px;
    z-index: 1000;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .25);
    border-radius: 5px;
    background-color: #172d44;
    transition: all 0.2s ease;
    opacity: .25;

    &:hover {
        opacity: 1;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .25);

        a {
            font-size: 11px!important;
        }
    }

    a {
        color: #fff;
        font-size: 9px!important;
        transition: all 0.2s ease;

        &:hover {
            text-decoration: none;
            color:rgb(255, 196, 87);
        }

        &:focus {
            outline: none;
            box-shadow: none;
            transform: scale(1.1);
        }

        i {
            margin-right: .25rem;
        }
    }
}

.keditor-iframe-body {
    span {
        &.label {
            padding: .15em .4em .35em .25em;
            color: #fff;
            background-color: #172d44;
            border-radius: 4px;
            font-size: .75em;
            top: -.15em;
            position: relative;
            line-height: 2em;
            white-space: nowrap;
            display: inline;
        }
    }
}

span[data-mutator] {
    // text-decoration: #172d44 overline underline wavy;
    outline: 2px dotted #172d44;
    outline-offset: 2px;

    span[data-mutator] {
        outline-offset: 6px;
    }
}

.chocolat-wrapper {
    z-index: 101!important;
}

dl {
    dd {
        color: #333;
        margin-left: 1rem;
    }
}

mark {
    background: rgb(255, 196, 87);
}

.cookie-consent {
    position: fixed;
    bottom: 0;
    background: #ee5050;
    width: 100%;
    text-align: center;
    padding: 1rem;
    color: #fff;
    z-index: 100;

    .cookie-consent__agree {
        transition: all .2s ease;
        color: #506690;
        background-color: #fff;
        border-color: #fff;
        box-shadow: 0 0.125rem 0.25rem rgb(13 20 49 / 7%);
        display: inline-block;
        font-weight: 600;
        border: .0625rem solid transparent;
        padding: .5rem 1.25rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .5rem;
        margin-left: 1.5rem;
    }
}