.footer {
	position: relative;
	padding-top: 7.5rem;
	padding-bottom: 6rem;
	background: #1c1c1c;
	color: #fff;
	font-size: .8rem;
	line-height: 2.06;

	.navbar-brand {
		font: 700 1.65rem/.73 "Roboto";
	}

	.decoration-circles {
		position: absolute;
		top: 96px;
		left: -60px;
	}

	a {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: #ffc107;
		}
	}

	h2 {
		font-size: 1rem;
		line-height: 1.2;
		color: #fff;

		u {
			border-bottom: 2px solid #ffc107;
		}
	}

	p {
		line-height: 2.06;

		&.lead {
			font-size: .8rem;
			font-weight: 500;
			letter-spacing: .077rem;
			line-height: 2.31;
			color: #ffc107;
			text-transform: uppercase;
		}
	}

	h4 {
		font-size: 1.1rem;
		line-height: 1;
		color: #fff;
		margin-bottom: .5rem;
	}

	.links-support {
		font-size: 1.25rem;
	}

	ul {
		list-style: none;

		// .list-inline-item:not(:last-child) {
		// 	margin-right: 3rem;
		// }

		&.list-links-social {
			li {
				display: inline;

				&:not(:last-child) {
					&::after {
						content: '|';
						color: #ffc107;
						margin-left: .2rem;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.footer {
		h4 {
			font-size: .8rem;
			margin-bottom: .75rem;
		}

		ul {
			&.list-links-social {
				li {
					display: block;

					&:not(:last-child) {
						&::after {
							content: none;
						}
					}
				}
			}
		}
	}
}