$background-blur: rgba(217, 217, 217, 6%);
$blue-dark: #13202b;
$color-input-placeholder: rgba(255, 255, 255, 40%);
$color-input: #FFFFFF;
$gold: #FCE7C7;
//$gold-active: #FFC107;
$gold-active: #FFFFFF;
$background-input: $blue-dark;

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
