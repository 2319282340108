.navbar {
    font-size: 1.45rem;
    line-height: 1.5rem;
    letter-spacing: .025rem;
    text-transform: uppercase;
    padding: 0;

    #main-nav {
        .scrollable {
            overflow: auto;
            max-height: 100vh;
            padding-bottom: 5rem;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.top-nav-collapse {
        .logo-image {
            width: 160px;
        }
    }

    .navbar-brand {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .logo-image {
        text-align: center;
        padding: 0 70px;
        margin-top: 20px;
    }

    .navbar-nav {
        margin-top: 2.5rem;
        margin-bottom: 0.5rem;
        padding-left: 4.3rem;

        .nav-item {
            position: relative;

            .nav-link {
                color: $gold;
                text-decoration: none;
                font-weight: 500;

                &:hover {
                    color: $gold-active;
                }

                &.active,
                &.route-active {
                    color: #fff;
                    font-weight: 600;

                    &:before {
                        content: "◆";
                        position: absolute;
                        margin-left: -1.5rem;
                        margin-top: -.1rem;
                        font-size: 1rem;
                    }

                    &:hover {
                        color: $gold-active;
                    }
                }
            }
        }
    }

    .social-links {
        padding: 0 5.5rem 0 4.5rem;
        font-size: 1.75rem !important;
        margin-top: 4.5rem !important;

        a {
            margin: 0;

            i {
                font-weight: 300 !important;
            }
        }
    }

    .navbar-toggler {
        padding: 1rem;
        border: none;
        font-size: 1.2rem;
        position: relative;
        left: 0;
        top: .25rem;

        &:focus {
            box-shadow: none;
        }

        .bullet {
            &:before {
                content: "\25C6";
                color: $gold;
                border: none;
            }
        }

        &.navbar-toggler-cross {
            position: absolute;
            left: auto;
            right: 1.25rem;
            top: 0.75rem;
            color: $gold;
            outline: none;
            font-size: 2.5rem;
            padding: 0;

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-toggler-icon {
            background: none;
            font-family: "Font Awesome 5 Free";
            content: "\f0c9";
        }
    }

    .nav-lang {
        color: $gold;
        margin: 30px 0;

        .nav-item {
            line-height: 3rem;
            position: relative;
            padding: 0 1.5rem;

            &:not(:last-child) {
                padding-right: .5rem;

                &::after {
                    content: '|';
                    margin: 0 .25rem;
                    font-size: 3.8rem;
                    font-weight: 200;
                    position: absolute;
                    top: -7%;
                    right: -33%;
                }
            }

            .nav-link {
                padding-left: .25rem;
                padding-right: 0;
                text-decoration: none;

                &:hover {
                    color: $gold-active;
                }
            }
        }
    }

    #nav-search {
        padding-left: 1rem;
        padding-right: 2rem;

        button {
            color: #ffcc00;
        }
    }
}
