html,
body {
	width: 100%;
	height: 100%;
	font: 400 14px/30px "Montserrat", sans-serif;
    background: darken($blue-dark, 2%);
    color: #EFF3FB;
}

/*
#background-fake {
    width: 100vw;
    height: 100vh;
    background: $blue-dark url('../../images/background.jpg') no-repeat;
    background-size: 100vw 100vh;
    background-attachment: fixed;
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
}
*/

body {
	display: flex;
	height: auto !important;
	flex-direction: column;
}

main {
	flex: 1;
    z-index: 1;
}

h1,
h2 {
	color: $blue-dark;
    background: $gold;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: row;

	u {
		display: inline-block;
		line-height: 1.5rem;
		text-decoration: none;
		border-bottom: 3px solid #ffc107;
	}

    a {
        color: $blue-dark;
        font-size: 80%;

        &:hover {
            color: $blue-dark;
            text-decoration: none;
        }
    }

    &:after,
    &:before {
        content: " ";
        border-bottom: 3px solid $blue-dark;
        flex: 1 1;
        margin: auto .75rem;
    }

    &.top {
        padding: 0 23%!important;
        margin-bottom: 1.5rem;

        &:after,
        &:before {
            margin: auto 1rem;
        }
    }

    &.inverted {
        color: $gold;
        background: transparent;
        padding: 0;

        &:after,
        &:before {
            margin: auto 1.5rem;
            border-bottom: 2px solid $gold;
        }

        &:before {
            margin-left: 0;
        }

        &:after {
            margin-right: 0;
        }
    }

    .lines {
        &:after,
        &:before {
            display: inline-block;
            //width: 25%;
            width: 20%;
            content: " ";
            border-bottom: 3px solid $blue-dark;
            margin: auto .75rem;
            position: relative;
            top: -6px;
        }

        &:before {
            margin-left: 0;
        }

        &:after {
            margin-right: 0;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
	//margin-bottom: 2rem;
    margin-bottom: 0;
}

h1 {
	// font-size: 2.75rem;
    font-size: 2.15rem;
    padding: .9rem;
}

h2 {
	// font-size: 2.1rem;
	font-size: 1.785rem;

    &.a {
        outline: 2px solid $gold;
        border: 5px solid $blue-dark;
        @include rx-transition();
        //font-size: 1.785rem;
        font-size: 1.9rem;

        a {
            display: block;
            width: 100%;
            padding: 1rem 0;

            &:after,
            &:before {
                content: "\25C6";
                color: $blue-dark;
                border: none;
                position: relative;
                font-size: 1.4rem;
            }

            &:before {
                left: -2%;
                top: -.15rem;
            }

            &:after {
                right: -2%;
                top: -.15rem;
            }
        }

        &:hover {
            background: $gold-active;
        }
    }
}

h3 {
	font-size: 1.466rem;
    color: $gold;
    text-transform: uppercase;
}

h4 {
	font-size: 1.5rem;
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1rem;
}

p {
	line-height: 2.05rem;
    padding: .3rem 2rem;
    margin: 0;
    font-size: 1.1rem;

    &.lead {
        font-size: 1.57rem;
        font-weight: 500;
        color: $gold;
        text-transform: uppercase;
        margin-bottom: 2rem;
        padding: 0;
    }

	&.text-clamp {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

    &.box {
        padding: 2.6rem 2.15rem 2.2rem;
        background: rgba(217, 217, 217, .05);
        //background: rgba(217, 0, 0, .05);
    }
}

i {
	&.fa-brands {
		font-style: normal;
	}
}

.text-uppercase {
	text-transform: uppercase;
}

.text-oswald {
	font-family: "Oswald";
	font-weight: 600;
}

.p-large {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.p-small {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.testimonial-text {
	font-style: italic;
}

.testimonial-author {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.li-space-lg li {
	margin-bottom: 0.5rem;
}

a {
    color: #ffc107;
    text-decoration: none;
    transition: all .2s ease;

    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}

.no-line {
	text-decoration: none;
}

.no-line:hover {
	text-decoration: none;
}

.blue {
	color: #0092ff;
}

.bg-gray {
	background-color: #fcfafb;
}

.section-title {
	margin-bottom: 0.5rem;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
}

.form-control-submit {
    border-radius: 0;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.33em;
    padding: 1rem 2rem;

	&:focus {
		box-shadow: 0 0 0 .25rem rgba(255, 204 , 0, .15);
	}

    &.btn-solid {
        background: $gold;
        color: #000;

        &:hover {
            background: darken($gold, 10%);
        }
    }
}

.form-group {
	position: relative;
	margin-bottom: 1.25rem;
}

.form-control {
    font-size: 1em;

    &:focus {
        color: $color-input;
        box-shadow: none;
        background: $background-input;
    }
}

.form-control-input,
.form-control-textarea {
    border: 1px solid $background-input;
    background: $background-input;
    color: $color-input;
    padding: 1em;
    border-radius: 0;
    -webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */

    &::placeholder {
        color: $color-input-placeholder;
        text-transform: uppercase;
        //position: absolute;
        //bottom: .5rem;
        opacity: 1;
    }
}

.form-control-textarea {
	display: block;
	height: 14rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
}

.form-control-input:focus,
.form-control-textarea:focus {
	border: 1px solid $gold;
	outline: none; /* Removes blue border on focus */
}

.form-check-inline {
    .form-check-input {
        margin-top: .6rem;
    }
}

.col-form-label {
    font-size: .9rem;
}

.border-gold {
    border: 2px solid $gold;
}

.border-left-gold {
    border-left: 2px solid $gold;
}

.img-blur {
    filter: blur(10px);
    /* this is needed so Safari keeps sharp edges */
    transform: scale(1);
    height: 100%;
}

.img-preloader {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    display: block;

    img {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        @include rx-transition();

        &.loaded {
            opacity: 1;
        }
    }
}

.input-group {
    &.is-invalid {
        .invalid-feedback {
            display: block;
        }
    }
}

div {
    &.box {
        padding: 2rem;
        background: rgba(217, 217, 217, .05);

        p {
            margin: 0;
            padding: 0;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                display: flex;

                &:before {
                    content: "\25C6";
                    color: $gold;
                    margin-right: 1rem;
                }
            }
        }
    }

    &.bullet-top {
        &:before {
            content: "\25C6";
            color: $gold;
            border: none;
            position: relative;
            left: 49.25%;
            margin-left: -4px;
        }
    }

    &.bullet-bottom {
        &:after {
            content: "\25C6";
            color: $gold;
            border: none;
            position: relative;
            left: 49.25%;
            margin-left: -4px;
        }
    }

    &.bullet-bottom-offset {
        &:after {
            margin-left: 6px;
        }
    }

    &.bullet-left {
        position: relative;

        &:before {
            content: "\25C6";
            color: $gold;
            border: none;
            position: absolute;
            left: -2.2rem;
            top: 2px;
        }
    }

    &.bullet-right {
        position: relative;

        &:after {
            content: "\25C6";
            color: $gold;
            border: none;
            position: absolute;
            right: -2.2rem;
            top: 2px;
        }
    }

    &.vertical-line {
        position: relative;
        margin-bottom: 5rem;

        &:after {
            content: " ";
            border: 1px solid $gold;
            position: absolute;
            left: 49.5%;
            margin-left: -1px;
            display: block;
            height: 3.55rem;
        }
    }

    &.vertical-lines-offset {
        position: relative;

        &:before {
            content: " ";
            border: 1px solid $gold;
            position: absolute;
            left: 41.9%;
            margin-left: -4px;
            display: block;
            margin-top: -3rem;
            height: 3rem;
        }

        &:after {
            content: " ";
            border: 1px solid $gold;
            position: absolute;
            left: 41.9%;
            margin-left: -4px;
            display: block;
            height: 3rem;
        }
    }
}

hr {
    border: 1px solid $gold;
    opacity: 1;

    &.vertical-line {
        position: relative;
        border: none;
        border-top: 1px solid $gold;
        margin: 0;
        padding-bottom: 5rem;

        &:after {
            content: " ";
            border: 1px solid $gold;
            position: absolute;
            left: 49.5%;
            margin-left: -1px;
            display: block;
            height: 3.55rem;
        }
    }
}

.last-section {
    padding-bottom: 5rem;

    &:after {
        margin-top: 2rem;
    }
}

:focus-visible {
    outline: none;
}
