@media (max-width: 767px) {
}

@media (min-width: 768px) {
}

@media (max-width: 991px) {
    h1,
    h2 {
        &.top {
            padding: 0!important;
            display: block;

            &:after,
            &:before {
                content: none;
            }
        }

        &.a {
            a {
                &:before,
                &:after {
                    content: none;
                }

                span {
                    display: inline-block;
                }
            }
        }

        .lines {
            &:before,
            &:after {
                content: none;
            }
        }

        &.inverted {
            font-size: 2rem;
            padding: 1rem 0;

            &:before,
            &:after {
                content: none;
            }
        }

        &.mobile-no-decoration {
            justify-content: center;

            &:before,
            &:after {
                content: none;
            }
        }
    }

    .col-nav,
    .col-main {
        width: 100vw;
        overflow: hidden;
    }

    .col-first {
        width: 100%;

        &.col-first-homestaging,
        &.col-first-consulting {
            width: 100%;
        }
    }

    .col-second {
        width: 100%;

        &.col-second-homestaging,
        &.col-second-consulting {
            width: 100%;
        }
    }

    .col-about-first {
        width: 100%;
    }

    .col-about-second {
        width: 100%;
        border: none!important;
    }

    section {
        p {
            &#homestaging-intro,
            &#homestaging-foot {
                padding: 1.82rem 1.5rem 1.82rem 2rem!important;
            }
            &#reconstruction-description {
                padding: 5.5rem 1.5rem 1rem 2rem!important;
            }
        }

        div {
            &#homestaging-services {
                padding: 2.2rem 1.8rem 3.7rem 2.75rem;
            }
            &#database-advantages {
                padding: 2rem 2rem 2.3rem 2.3rem;
            }
            &#about-values {
                padding: 1.5rem 1rem 0 3rem;
            }

            &.bullet-left {
                &:before {
                    content: none;
                }
            }
            &.bullet-right {
                &:after {
                    content: none;
                }
            }
        }

        &.section-estate-list {
            .estate-list-data-container {
                .estate-list-data-container-item {
                    .middle {
                        top: 40%;
                    }
                }
            }
        }
    }

    .swiper-button {
        &:after {
            display: none!important;
        }
    }

    section.section-estate-detail .estate-detail-data-container .estate-detail-gallery {
        margin: 0!important;
    }

    .estate-detail-description {
        h1 {
            text-align: center!important;
        }
    }
}

@media (min-width: 992px) {
    .col-first {
        width: 48%;
    }

    .col-second {
        width: 52%;
    }

    .col-about-first {
        width: 50.75%;
    }

    .col-about-second {
        width: 49.25%;
    }
}

@media (max-width: 1199px) {
    section {
        .content-container {
            padding: 6rem 1rem 0rem 1rem;
        }
    }

    .navbar {
        &.nav-expand {
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 4rem;
            //background: $blue-dark url('../../images/background.jpg') no-repeat;
            background: darken($blue-dark, 2%);
            border-bottom: 2px solid $gold !important;
        }

        #main-nav {
            &.offcanvas {
                //background: $blue-dark url('../../images/background.jpg') no-repeat;
                background: darken($blue-dark, 2%);
                border-right: 0 !important;
                width: 100%;
                height: 100%;
                top: 0;
                transition: transform 1.3s ease 0s;
            }
        }

        .logo-image {
            //padding: 0 9rem;
            max-width: 360px;
            min-width: 200px;
            margin: 20px auto 0;
        }
    }

    section a.close-button,
    section a.back-button {
        position: fixed;
        z-index: 1000;
        font-size: 2.5rem;
        right: 1.25rem;
        top: .75rem;
        outline: none;
    }
}

@media (min-width: 1200px) {
    .navbar {
        .navbar-nav {
            flex-direction: column;
        }

        #main-nav {
            border-right: 2px solid $gold !important;

            width: 330px !important;
            position: fixed;
            top: 34px;
            bottom: 34px;
            left: auto;
        }
    }

	.container {
		max-width: 1200px;
	}
}
