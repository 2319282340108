.section-contact {
    .bootstrap-select {
        width: 100%;

        .filter-option {
            font-size: .85rem;
        }

        & > .dropdown-toggle {
            border: 1px solid $gold;
            background: transparent;
            //color: $color-input;
            padding: .25em .5em;
            border-radius: 0;
            text-transform: uppercase;
            color: $color-input-placeholder;

            &:focus {
                outline: none !important;
            }

            &:after {
                content: "\25C6";
                color: $gold;
                border: none;
            }
        }

        .option-selected {
            & ~ .dropdown-toggle {
                color: $color-input;
            }
        }

        .dropdown-menu {
            background: $background-input;

            .dropdown-item {
                color: $color-input;

                &:focus,
                &:hover {
                    background: $background-blur;
                    color: $color-input;
                }

                &.active,
                &:active {
                    background: $background-blur;
                    color: $color-input;
                }
            }
        }
    }

    .status {
        background: $background-input;
        color: $color-input;
        font-size: 10px;
    }
}
