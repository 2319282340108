@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@600&family=Roboto:wght@700&display=swap');

@import "../css/bootstrap.min.css";
@import "../css/fontawesome-all.min.css";
@import "~swiper/swiper-bundle.min.css";
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "~ajax-bootstrap-select/dist/css/ajax-bootstrap-select.min.css";
@import "~intl-tel-input/build/css/intlTelInput.css";

@mixin rx-transition() {
    @include transition(all .25s ease-in-out);
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@import "front/variables";
@import "front/vendor";
@import "front/grid";
@import "front/general";
@import "front/footer";
@import "front/navigation";
@import "front/section";
@import "front/article";
@import "front/media";
