section {
    &.section-header {
        padding: 4rem 0 12rem;

        h1 {
            font-size: 1.95rem;
            letter-spacing: .01rem;
        }

        p {
            font-size: 1rem;
            letter-spacing: 0;
        }
    }

    a {
        &.close-button,
        &.back-button {
            color: $gold;
            font-size: 2rem;
            position: absolute;
            right: 0rem;
            top: 3.25rem;
            text-decoration: none;

            &:hover {
                color: $gold-active;
            }
        }

        &.back-button {
            top: 12.5rem;
            //left: 1rem;

            &:after {
                font-size: 1rem;
                content: "\25C6";
                position: relative;
                top: -6px;
                left: -6px;
            }
        }
    }

    &.section-home,
    &.section-contact,
    &.section-estate-list,
    &.section-estate-detail {
        .contact-box {
            background: $background-blur;
            padding: 1.5em;
            margin-top: -3px;
            font-size: 1rem;
        }

        .estate-data-container {
            padding: 11rem 1.5rem 0 1.5rem;
        }

        textarea {
            height: 10rem;
        }

        fieldset {
            legend {
                color: #FFF;
                font-size: .9em;
            }

            &.estate {
                .input-group {
                    margin-bottom: 1.5rem;
                }

                .form-control-input {
                    padding: .45em 1em;
                    text-transform: none;
                    border: 1px solid $gold;
                    background: transparent;
                }
            }
        }

        .social-links {
            font-size: 2rem;
            margin-top: 3rem;

            a {
                color: $gold;
                text-decoration: none;
                //margin: 0 2rem;

                &:hover {
                    color: #fff;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .content-container {
        padding: 2.7rem 4.15rem 0 2.95rem;
    }

    &.section-home {

    }

    &.section-estate-list {
        .estate-list-data-container {
            margin: 0 0 0 .8rem;

            .estate-list-data-container-item {
                padding: 0;
                margin: -1px;
                position: relative;

                .middle {
                    @include rx-transition();
                    opacity: 0;
                    position: absolute;
                    display: block;
                    top: 60%;
                    left: 0;
                    background: $blue-dark;
                    border: 2px solid $gold;
                    color: $gold;
                    padding: .75rem 1rem;
                    width: 100%;
                    text-transform: uppercase;
                    text-decoration: none;
                    line-height: 20px;

                    &:hover {
                        color: $gold-active;
                    }
                }

                .img-preloader {
                    @include rx-transition();
                }

                &:hover {
                    .img-preloader {
                        //opacity: .3;
                    }

                    .middle {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.section-estate-detail {
        .estate-detail-data-container {
            .estate-detail-gallery {
                margin: 0 2.2rem 1.5rem 0;
            }

            .estate-detail-description {
                margin: 0 2.2rem 0 1rem;

                p {
                    padding: 0 0 1rem 0;
                }
            }

            dl {
                font-size: .9rem;

                dt {
                    margin: 0;
                    color: $gold;
                    text-transform: uppercase;
                    font-weight: 500;
                    line-height: 3rem;
                }

                dd {
                    margin: 0;
                    color: #fff;
                    text-align: right;
                    line-height: 3rem;
                }

                dt:nth-of-type(2n + 1) {
                    background: rgba(255, 255, 255, .1);

                    & + dd {
                        background: rgba(255, 255, 255, .1);
                    }
                }
            }

            h1,
            h2 {
                background: transparent;
                color: $gold;
                text-align: left;
                display: block;
                margin: 1rem 0;
                padding: 0;

                &:after,
                &:before {
                    display: none;
                }
            }

            h1 {
                font-size: 1.667rem;
            }

            h2 {
                font-size: 1rem;
            }

            fieldset {
                font-size: .9rem !important;

                p {
                    &.lead {
                        font-size: .9rem !important;
                    }
                }

                .form-control {
                    font-size: .9rem !important;
                }

                .form-control-submit {
                    font-size: .9rem !important;
                    width: 100%;
                }
            }

            .swiper-container {
                .swiper-button {
                    height: 90px;
                    top: auto;
                    bottom: 0;
                }
            }
        }
    }

    .image-container {
        img {
            width: 100%;
        }
    }

    .card-shadow {
        padding: 1rem 2rem;
        border-radius: .65rem;
        box-shadow: 0 1.5px 27px 0 rgba(40, 40, 40, .13);
        border: solid .5px #979797;
    }

    blockquote {
        font-size: 1.15rem;
        line-height: 1.8;
        letter-spacing: 0;
    }

    figcaption {
        font-size: .9rem;
        line-height: 2.5;
    }

    .list-tags {
        li {
            font-size: .6rem;
            line-height: 1.33;
            letter-spacing: normal;

            &::before {
                color: #343539;
                content: "#";
            }
        }

        a {
            color: #272829;
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: #ffcc00;
            }

            &.selected {
                border-bottom: 2px solid #ffcc00;
            }
        }
    }

    p {
        &#homestaging-intro {
            padding: 3rem 1.9rem 3.5rem 2.75rem;
        }
        &#homestaging-foot {
            padding: .25rem 1.9rem 2.5rem 2.75rem;
        }
        &#reconstruction-description {
            padding: 5.5rem 1.3rem 1rem 3rem;
        }
    }

    div {
        &#homestaging-services {
            padding: 2.2rem 2.75rem 3.7rem;
        }
        &#database-advantages {
            padding: 1.3rem 0 2.3rem 3.5rem;
        }
        &#about-values {
            padding: .5rem .5rem 0 3.3rem;
        }
    }
}
