.section-article-detail {
	padding-top: 6rem;

	&.composition {
		padding: 0!important;
	}

	header {
		ul {
			list-style: none;
			margin: 1rem 0 1rem 0;
			padding: 0;
			font-size: .6rem;
			font-weight: 700;
			text-transform: uppercase;

			li {
				display: inline;
				margin: 0 1rem;

				a {
					text-decoration: none;
				}

				&.date {
					color: #272829;
				}

				&.category {
					color: #ffca05;
				}
			}
		}

		h1 {
			// font-size: 2.9rem;
			font-size: 2.465rem;
		}

		p {
			font-size: 1.15rem;
		}

		.img-fluid {
			width: 100%;
		}

		.figure-caption {
			font-size: .65rem;
		}
	}

	.article-body {
		line-height: 1.5rem;
		padding: 2rem 0 0 0;
		font-size: .9rem;
		line-height: 1.83;

		h1 {
			font-size: 2.65rem;
			line-height: 1.1;
			letter-spacing: .09rem;
		}

		h2 {
			font-size: 2rem;
			line-height: 1.1;
			letter-spacing: .07rem;
		}

		h3 {
			font-size: 1.5rem;
			line-height: 1.27;
			letter-spacing: .05rem;
		}

		h4 {
			font-size: 1.1rem;
			line-height: 1.45;
			letter-spacing: .04rem;
		}

		p {
			font-size: .9rem;
			line-height: 1.65rem;
		}

		blockquote {
			font-size: 2.75rem;
			line-height: 1.27;
			font-weight: 600;
			letter-spacing: .05rem;
			position: relative;
			padding: 2rem 2rem 2rem 6rem;

			&::before {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				font-size: 3rem;
				content: "\f10d";
				color: #ffcc00;
				position: absolute;
				left: 1rem;
				top: 1.5rem;
			}
		}

		img {
			max-width: 100%;
		}

		ul {
			list-style: none;

			li {
				padding-left: 2rem;
				margin: 1.5rem 0;
			}

			li::before {
				content: "•";
				color: #ffcc00;
				font-weight: bold;
				display: inline-block;
				width: 2rem;
				margin-left: -2em;
			}
		}

		ol {
			list-style: none;
			counter-reset: clim-counter;

			li {
				padding-left: 2rem;
				margin: 1.5rem 0;
				counter-increment: clim-counter;
		  	}

			li::before {
				content: counter(clim-counter) ". ";
				color: #ffcc00;
				font-weight: bold;
				display: inline-block;
				width: 2rem;
				margin-left: -2em;
			}
		}

		.text,
		.image,
		.gallery,
		.video {
			margin: 0 0 2rem 0;
		}

		.gallery {
			img {
				width: 100%;
			}
		}

		.video {
			position: relative;
			padding-bottom: 56.25%;
			/* 16:9 */
			padding-top: 25px;
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.figure-caption {
			font-size: .7rem;
			line-height: 1.43;
			color: #000;
		}
	}

	.article-footer {
		padding: 1rem 0;
		border-top: 1px solid #13161a;
	}

	.article-related {
		h2 {
			font-size: 2rem;
			line-height: 1.1;
			letter-spacing: .07rem;
		}
	}
}