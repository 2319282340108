.swiper-container {
    position: relative;

    .swiper {
        .swiper-slide {
            cursor: pointer;

            &.swiper-slide-player {
                z-index: 1;
            }

            figure {
                margin: 0;
                float: left;
            }

            iframe {
                width: 100%;
                //height: 100%;
                float: left;
            }
        }
    }

    .swiper-button {
        --swiper-navigation-color: $gold;
        --swiper-navigation-size: 1rem;
        height: 100%;

        &.swiper-button-next,
        &.swiper-button-prev {
            top: .75rem;
        }

        &.swiper-button-prev {
            left: -1.8rem;
            padding-right: 2rem;

            &:after {
                color: $gold;
                content: "\25C6";
            }
        }

        &.swiper-button-next {
            right: -2rem;
            padding-left: 2rem;

            &:after {
                color: $gold;
                content: "\25C6";
            }
        }
    }
}
