.container {
    //background: url('../../images/mask.jpg') no-repeat center top;
    max-width: 900px;
}

.col-nav {
    flex: 0 0 auto;
    width: 325px;
}

.col-main {
    flex: 0 0 auto;
    width: 875px;
}

.col-first {
    padding: 0 1% 1% .5%;

    &.col-first-homestaging {
        width: 49%;
        padding-right: 0;
        padding-left: 1.25%;
    }

    &.col-first-consulting {
        width: 49.4%;
        padding: .3rem 0 0 .7rem;
    }
}

.col-second {
    &.col-second-homestaging {
        width: 51%;
        padding-left: .5%;
    }

    &.col-second-consulting {
        width: 50.6%;
    }
}

.my-125 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.mt-125 {
    margin-top: 1.25rem;
}

.pt-125 {
    padding-top: 1.25rem;
}
